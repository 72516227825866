.darkMode {
  /* background-color: #303030; */
  background-color: #282c34;
  color: #ffffff;
  /* height: screen; */
  /* padding-bottom: 7rem; */
}

.lightMode {
  background-color: #ffffff;
  color: #272b33;
  /* padding-bottom: 7rem; */
}
