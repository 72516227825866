/* .chart .apexcharts-toolbar {
  display: none;
} */

.ant-modal .ant-modal-content {
  padding: 0px !important;
}

.ant-modal .ant-modal-header {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

/* #dark .ant-modal .ant-modal-header {
  background-color: #282c34 !important;
} */

.small .apexcharts-data-labels text {
  font-size: 0px !important;
}

.modal .apexcharts-data-labels text {
  font-size: 0px !important;
}

.anticon svg {
  display: flex !important;
}

/* .chart #SvgjsSvg35359 #SvgjsG35361 #SvgjsG35425 #SvgjsLine35453 {
  y1: attr("155") !important;
  y2: attr("155") !important;
  background-color: blue !important;
} */

/* .chart #SvgjsLine9236 {
  y1: attr("155") !important;
  y2: attr("155") !important; 
}

.chart #SvgjsSvg35359 #SvgjsG35361 #SvgjsG35425 #SvgjsLine35533 {
  y1: attr("156") !important;
  y2: attr("156") !important;
} */

/* .apexcharts-xaxistooltip {
  display: none;
}

.apexcharts-yaxistooltip {
  display: none;
} */

.chart .apexcharts-xaxis-tick {
  display: none;
}
