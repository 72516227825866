/* Profile View Styles */
.profile-view {
    display: flex;
    /* align-items: center; */
    cursor: pointer;
    padding: 5px;
  }
  
  .profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-name {
    font-weight: bold;
  }
  
  /* Profile Menu Styles */
  .profile-menu {
    color: black;
    position: absolute;
    top: 50px; /* Adjust the top position as needed */
    /* right: 0; */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* display: none; */
    margin-top: 2px;
  }
  
  .profile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .profile-menu li {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .profile-menu li:hover {
    background-color: #f0f0f0;
  }
  